<template>
      <v-container>
            <div class="item__column loan">
                
                <div class="item__row item__ac loan__header item__100 item__jb">
                    <div class="item__row item__ac item__logo">
                        <img src="../assets/img/loan.png" alt="" />
                        <p class="loan__title mb-4">Qaryz kitap</p>
                    </div>
                    <img class="loan__logout" @click="logout()" src="../assets/img/logout.png" alt="" />
                </div> 


                <div class="item__row item__ac mb-4" v-if="overdue!=0">

                    <p class="mr-2 loan__overdue item__abs">{{ overdue }}</p>

                    <div>
                        <p class="loan__subtitle__label">Есть просроченный долг</p>
                        <p class="loan__show__overdue" @click="loanModal=true">Показать</p>
                    </div>
                </div>

                <div class="item__row item__ac mb-4">
                    <p class="loan__result__label">Дал в долг: </p>
                    <p>&nbsp;{{formatNumber(given_loan)}}  ₸</p>
                </div>

                <div class="item__row item__ac mb-4">
                    <p class="loan__result__label">Взял в долг: </p>
                    <p>&nbsp;{{ formatNumber(borrowed) }}  ₸</p>
                </div>

                <div class="loan__add__btn item__row item__ac item__abs" @click="dialog=true">
                        <i class="mdi mdi-plus-circle"></i>
                        <p class="loan__subtitle">Новый долг</p>
                </div>
                <p class="loan__info">Нажмите кнопку чтобы добавить новый долг</p>

                
                <div class="item__row item__ac item__100 item__jb mt-10  mb-4">
                    <p class="loan__subtitle">Активные долги</p>
                    <p class="loan__all" @click="getLoanAll()">Cм. все</p>
                </div>
                <div class="loan__list item__row mb-6">
                    
                    <div class="loan__list__item item__column item__ac" v-for="(loan,index) in items" :key="index">
                        <p class="loan__list__user">{{loan.client.fio}}</p>
                        <p class="loan__list__phone-number">{{loan.client.phone}}</p>

                      <div v-if="loan.type==='vzyal'">
                        <p class="loan__list__type" >Взял в долг</p>
                        <p class="loan__list__amount">{{formatNumber(loan.money)}} ₸</p>
                      </div>

                      <div v-else>
                        <p class="loan__list__type" >Дал в долг</p>
                        <p class="loan__list__amount">{{formatNumber(loan.money)}} ₸</p>

                      </div>

                        <div class="loan__list__date item__100 item__row item__jb">
                            <p>создан: {{formatRangeDate(loan.date_given)}}</p>
                          
                            <p class="loan__list__overdue" v-if="loan.overdue=='yes'">до: {{formatRangeDate(loan.date_back)}}</p>
                            <p v-else>до: {{formatRangeDate(loan.date_back)}}</p>

                        </div>

                        <div class="loan__list__edit item__abs" @click="getLoan(loan.id)">
                            <i class="mdi mdi-lead-pencil"></i>
                        </div>
                        <a class="loan__list__phone item__abs" :href="'tel:'+loan.client.phone">
                        
                            <i class="mdi   mdi-phone"></i>
                        </a>
                    </div>
                </div>

                                
                <div class="item__row item__ac item__100 item__jb mt-10  mb-4">
                    <p class="loan__subtitle">Статистика долгов на год</p>
                </div>
                <div class="loan__list item__row mb-6">
                    
                    <div class="loan__list__item item__column item__ac" v-for="(loan,index) in statistics" :key="index">
     
                        <p class="loan__list__type" >{{loan.month}}</p>
                        <p class="loan__list__amount">{{formatNumber(loan.freq)}} ₸</p>

                  
                    </div>
                </div>
                <!-- <div class="loan__download item__ac item__row" @click="downloadLoan()">
                    <i class="mdi mdi-cloud-download mr-2"></i>
                    <p>Выгрузить долги</p>
                
                </div>

                -->

            </div>  
            
            <v-dialog
                v-model="loanModal"
                fullscreen
                transition="dialog-bottom-transition"
            >
                 <v-card>
                    <div class="item__column item__ac item__100 loan__modal">

                        
                        <i class=" mdi mdi-arrow-left-bold loan__modal__back" @click="closeLoanModal"></i>
                       
                        <div class="item__row item__ac item__100 loan__modal__search">
                            <input v-model="search" placeholder="Поиск (фио,телефон)" class="mr-2 mt-2 mb-4 loan__modal__input" /> 
                            <i class="mdi mdi-magnify"></i>
                        </div>
                        
                       <div class="loan__modal__types item__row item__ac">
                                <p class="loan__modal__type" @click="getLoanByType('dal')" v-bind:class="{'loan__modal__type__active':fetch_loan_type=='dal'}">Дал в долг</p>
                                <p class="loan__modal__type" @click="getLoanByType('vzyal')" v-bind:class="{'loan__modal__type__active':fetch_loan_type=='vzyal'}">Взял в долг</p>
                        </div>

                        <div class="loan__modal__list item__column item__ac" >
                            <div class="loan__list__item item__column item__ac" v-for="(loan,index) in loans" :key="index">
                                <p class="loan__list__user">{{loan.client.fio}}</p>
                             
                                <p class="loan__list__type" v-if="loan.type === 'dal'">Дал в долг</p>
                                 <p class="loan__list__type" v-else>Взял в долг</p>

                                <p class="loan__list__amount">{{loan.money}} ₸</p>

                                <div class="loan__list__date item__100 item__row item__jb">


                                    <p>создан: {{formatRangeDate(loan.date_given)}}</p>
                                    

                                    <p class="loan__list__overdue" v-if="loan.overdue=='yes'">до: {{formatRangeDate(loan.date_back)}}</p>
                                    <p v-else>до: {{formatRangeDate(loan.date_back)}}</p>


                                </div>


                                <div class="loan__list__edit item__abs" @click="getLoan(loan.id)">
                                    <i class="mdi mdi-lead-pencil"></i>
                                </div>
                                <a class="loan__list__phone item__abs" :href="'tel:'+loan.client.phone">
                                    <i class="mdi   mdi-phone"></i>
                                </a>
                            </div>
                        </div>
                    </div>

                 </v-card>
            </v-dialog>
            <v-dialog
                v-model="dialog"
                fullscreen
                hide-overlay
                transition="dialog-bottom-transition"
                scrollable
            >
                <v-card>
                    <v-form  @submit.prevent="callFunction()" ref="form" class="item__column loan__modal item__column item__jb pa-4">
                        <div class="loan__modal__header pa-1 item__row item__100 item__jb item__ac item__abs">
                            <p class="loan__subtitle" v-if="type==1">Новый долг</p>
                            <p class="loan__subtitle" v-else>Редактировать долг</p>
                            <i class="mdi mdi-close-circle loan__modal__close" @click="dialog=false"></i>
                        </div>


<!--                      <v-select-->
<!--                          v-model="selected_type"-->
<!--                          :items="data_type"-->
<!--                          item-text="label"-->
<!--                          item-value="value"-->
<!--                          label="Выберите тип долга"-->
<!--                          outlined-->
<!--                      ></v-select>-->


                      <div class="loan__types item__row item__ac">
                        <div class="loan__type" @click="loan_type='dal'" v-bind:class="{'loan__type__active':loan_type=='dal'}">Дал в долг</div>
                        <div class="loan__type"  @click="loan_type='vzyal'" v-bind:class="{'loan__type__active':loan_type=='vzyal'}">Взял в долг</div>
                      </div>

                   
                        <div class="item__column">
                            <p class="loan__subtitle__label">Сумма</p>
                            <p class="loan__info">Введите сумму и валюту</p>

                            <v-text-field
                                v-model="fio"
                                label="ФИО"
                                class="mb-6 mt-2"
                                filled
                                outlined
                                hide-details
                                :rules="inputRules"
                            ></v-text-field>
                            <v-text-field
                                v-model="money"
                                label="Сумма"
                                class="mb-6 mt-2"
                                filled
                                outlined
                                hide-details
                                :rules="inputRules"
                            ></v-text-field>
                            <!-- <v-text-field
                               v-model="phone"
                                label="Телефон"
                                filled
                                outlined
                                hide-details
                                :rules="inputRules"
                            ></v-text-field> -->

                            <masked-input v-model.trim="phone" id="phone" mask="8 (111) 111 11-11" />
                        </div>
                        

                      
                        <div class="item__column">
                           <p class="loan__subtitle__label">Дата создание и возврата</p>
                            <div class="loan__modal__date" @click="dateModal=true">
                                <p v-if="dates.length==0">Выбрать дату</p>
                                <p v-else-if="dates.length==2">{{formatRangeDate(dates[0]) +' - '+formatRangeDate(dates[1])}}</p>
                            </div>
                            <p class="loan__info">Здесь можно изменить дату создание и возврата долга</p>
                        </div>
                        <button type="submit" class="loan__modal__btn item__row item__ac item__abs">
                            <i class="mdi mdi-plus-circle"></i>
                            <p class="">Сохранить</p>
                        </button>


                    </v-form>
                
                </v-card>
            </v-dialog>
            <v-dialog v-model="dateModal" width="280">
                <v-card>
                    <v-date-picker v-model="dates" locale="ru-in" range></v-date-picker>
                </v-card>
            </v-dialog>
      </v-container>
</template>

<script>
import MaskedInput from 'vue-masked-input'

export default {
    components: {
        MaskedInput
    },
  data() {
    return {
        loans: [],
        selected_type: '',
        data_type: [
          {
            label: 'Одолжил',
            value: 'borrowed'
          },
          {
            label: 'Занял',
            value: 'lent'
          },
        ],
        me: '',
        showModal: false,
        items: [],
        loading:null,
        numberOfPages: null,
        totalPage: null,
        dialog: false,
        loanModal: false,
        type: 1,
        inputRules: [
            v => !!v || 'Заполните поле'
        ],
        
        dateModal: false,
        dates: [],
        fio: '',
        money: '',
        phone: '',
        loan_type: 'dal',
        clients: [],
        fetch_loan_type: '',
        given_loan: 0,
        borrowed: 0,
        overdue: 0,
        search: '',
        selectedLoan: '',
        statistics: []
    };
  },
  methods: {
    closeLoanModal() {
      this.loans = []
      this.loanModal=false
      this.fetch_loan_type = '';
      this.fetch()
    },
    getLoanAll() {
      this.loans = []
      this.items= []
      this.loanModal = true;
      this.fetch_loan_type = 'dal';
      this.fetchLoans()
    },
    getLoanByType(type) {
      this.fetch_loan_type = type
      this.fetchLoans()
    },
    getStatistics() {
        this.$axios
            .get(this.$API_URL + "statistics", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
        .then((response) => {
            this.statistics = response.data;
        })
        .catch((error) => {
           console.log(error);
        });
    },
    downloadLoan() {

    },
    formatNumber(number) {
      if (!number) {
        return 0;
      }
      let parts = number.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    },
    getResult() {
        this.$axios
            .get(this.$API_URL + "result/loan", {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response) => {
            this.given_loan = response.data.given_loan;
            this.borrowed = response.data.borrowed;
            this.overdue  = response.data.overdue;
        })
        .catch((error) => {
            if (error.response && error.response.status == 422) {
                this.$toast.open({
                message: "Заполните все поля",
                type: "error",
                position: "bottom",
                duration: 4000,
                queue: true,
                });
            }
        });
    },
    getLoan(id) {
        this.selectedLoan = id;
        this.type = 2;
        this.dialog = true;
        this.$axios
            .get(this.$API_URL + "loan/"+id, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`
            },
        })
        .then((response) => {
            this.fio = response.data.client.fio;
            this.phone = response.data.client.phone;
            this.money = response.data.money;
            this.dates = [];
            this.dates.push(response.data.date_given);
            this.dates.push(response.data.date_back);
            this.loan_type = response.data.type__loan==1?true:false;
            // this.type = 1;
        })
        .catch((error) => {
            if (error.response && error.response.status == 422) {
                this.$toast.open({
                message: "Заполните все поля",
                type: "error",
                position: "bottom",
                duration: 4000,
                queue: true,
                });
            }
        });
    },
    callFunction() {
        this.type==1?this.create():this.update();
    },
    create() {
        if(this.phone.replace(/[^0-9]/g,"").length!=11) {
            this.$toast.open({
                message: 'Не правильный телефон',
                type: "warning",
                position: "bottom",
                duration: 4000,
                queue: true,
            });
            return false;
        }
        this.$refs.form.validate();

        let contractForm = new FormData();
        contractForm.append("fio", this.fio);
        contractForm.append("phone", this.phone);
        contractForm.append("money", this.money);
        contractForm.append("type__loan", this.loan_type);
        contractForm.append("date_back", this.dates[1]);
        contractForm.append("date_given", this.dates[0]);
        contractForm.append("returned", 0);

        this.$axios
            .post(this.$API_URL + "loan", contractForm, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                "Content-Type": "multipart/form-data",
            },
        })
        .then((response) => {
            this.$toast.open({
                message: response.data.message,
                type: "success",
                position: "bottom",
                duration: 4000,
                queue: true,
            });
            this.dialog = false;
            this.fetch();
            this.getResult();
        })
        .catch((error) => {
            if (error.response && error.response.status == 422) {
                this.$toast.open({
                message: "Заполните все поля",
                type: "error",
                position: "bottom",
                duration: 4000,
                queue: true,
                });
            }
        });
    },
    update() {
        if(this.phone.replace(/[^0-9]/g,"").length!=11) {
            this.$toast.open({
                message: 'Не правильный телефон',
                type: "warning",
                position: "bottom",
                duration: 4000,
                queue: true,
            });
            return false;
        }
        this.$refs.form.validate();
    
        let obj = {
            fio: this.fio,
            phone: this.phone,
            money: this.money,
            type__loan: this.loan_type,
            date_back: this.dates[1],
            date_given: this.dates[0],
            returned: 0
        }
        this.$axios({
            method: 'put',
            url: this.$API_URL + "loan/"+this.selectedLoan,
            data: obj,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`
            },
        })
        .then((response) => {
            console.log(response);

            this.dialog = false;
            this.fetch();
            this.getResult();

        })
        .catch((error) => {
            console.log(error)
            this.$toast.open({
                message: "Не правильный логин или пароль",
                type: "warning",
                position: "bottom",
                duration: 4000,
                queue: true,
            });
        });
        // this.$axios
        //     .put(this.$API_URL + "loan/"+this.selectedLoan, 
        //     {
        //     data: {
        //         fio: this.fio,
        //         phone: this.phone
        //     }
        //     }
        //     , 
        //     {
        //     headers: {
        //         Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        //         "Content-Type": "multipart/form-data",
        //     },
        // })
        // .then((response) => {
        //     this.$toast.open({
        //         message: response.data.message,
        //         type: "success",
        //         position: "bottom",
        //         duration: 4000,
        //         queue: true,
        //     });
        //     this.dialog = false;
        // })
        // .catch((error) => {
        //     if (error.response && error.response.status == 422) {
        //         this.$toast.open({
        //         message: "Заполните все поля",
        //         type: "error",
        //         position: "bottom",
        //         duration: 4000,
        //         queue: true,
        //         });
        //     }
        // });
    },
    fetchClient(options) {
        this.loading = true;
        let url = "client";
        if(options)
            url = "client?type="+this.$route.query.type+'&per_page='+options.itemsPerPage+'&page='+options.page;
        this.$axios({
          method: "get",
          url:
            this.$API_URL +
            url,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
        })
        .then((response) => {
            this.clients = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async fetch(options = null) {
      this.loading = true;
      let url = "loan?search="+this.search+'&type_loan='+this.fetch_loan_type;
      if (options) {
        url = `loan?type=${this.$route.query.type}&per_page=${options.itemsPerPage}&page=${options.page}`;
      }

      try {
        const response = await this.$axios({
          method: "get",
          url: `${this.$API_URL}${url}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        this.items = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchLoans(options = null) {
      this.loading = true;
      let url = "loan?search="+this.search+'&type_loan='+this.fetch_loan_type;
      if (options) {
        url = `loan?type=${this.$route.query.type}&per_page=${options.itemsPerPage}&page=${options.page}`;
      }

      try {
        const response = await this.$axios({
          method: "get",
          url: `${this.$API_URL}${url}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        });
        this.loans = response.data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    formatDate(date) {
        if(date) {
            let d = date.split(' ')[0].split('-');


            let time = date.split(' ')[1].split(':');

            if(time)
                return d[2]+'-'+d[1]+'-'+d[0]+' '+time[0]+':'+time[1];
            else
                return d[2]+'-'+d[1]+'-'+d[0]
        }
    },
    formatRangeDate(date) {
        let d = date.split(' ')[0].split('-');
        return d[2]+'.'+d[1]+'.'+d[0]
    },
    createData(obj,files=null) {
            let contractForm = new FormData();
            contractForm.append("data", JSON.stringify(obj));
            contractForm.append("type", this.$route.query.type);
    
            if(files)
                for (var i = 0; i < files.length; i++) {
                    contractForm.append("files[]", files[i]);
                }  
            this.$axios
                .post(this.$API_URL + this.$API_VERSION + "page", contractForm, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                this.showModal = false;
                this.$toast.open({
                    message: response.data.message,
                    type: "success",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                });
            })
            .catch((error) => {
                if (error.response && error.response.status == 422) {
                    this.$toast.open({
                    message: "Заполните все поля",
                    type: "error",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                    });
                }
            });
    },
    updateData(obj,files,id) {

            this.$axios.put(this.$API_URL + this.$API_VERSION + "page/"+id,
                {
                    data: JSON.stringify(obj),
                    type: this.$route.query.type
                }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
            })
            .then((response) => {
                this.$toast.open({
                    message: response.data.message,
                    type: "success",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                });

                if(files.length>0)
                    this.uploadFiles(files,id);

                 this.showModal = false;
            })
            .catch((error) => {
                if (error.response && error.response.status == 422) {
                    this.$toast.open({
                    message: "Заполните все поля",
                    type: "error",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                    });

                }
            });     
    },
    uploadFiles(files,id) {
            let contractForm = new FormData();
            for (var i = 0; i < files.length; i++) {
                contractForm.append("files[]", files[i]);
            }
            this.$axios
                .post(this.$API_URL + this.$API_VERSION + "page/files/"+ id, contractForm, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                this.title =null;
                this.description = null;
                console.log(response);
                this.$toast.open({
                    message: "Успешно создано",
                    type: "success",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                });

                this.newsModal = false;
                this.type = 0;
                this.$refs.form.reset();
            

            })
            .catch((error) => {
                if (error.response && error.response.status == 422) {
                    this.$toast.open({
                    message: "Заполните все поля",
                    type: "error",
                    position: "bottom",
                    duration: 4000,
                    queue: true,
                    });

                }
            });
    },
    logout() {
        localStorage.clear();
        this.$router.push('/');
    },

  },
  mounted() {
    this.getResult();
    this.fetch();
    this.fetchClient();
    this.getStatistics();
  },
  beforeMount() {
      
  },
  watch: {
      search() {
            this.fetchLoans();
      },
      dialog(val) {
          if(!val) {
              
              this.type = 1;
              this.fio = '';
              this.phone = '';
              this.dates = [];
              this.money = '';

                this.$refs.form.reset()
              

          }
      }
  },
};
</script>

<style lang="scss">
 
    #phone {
        background: #f0f0f0;
        padding: 14px 12px;
        border-radius: 4px;
        border: 1px solid #959595;

    }
    .loan__title {
        font-size: 22px;
        font-weight:bold;
    }
    .loan__subtitle {
        font-size: 20px;
        font-weight: bold;
    }
    .loan__subtitle__label {
        font-size: 16px;
        font-weight: bold;
    }
    .loan__info {
        font-size:12px;
        color: gray;
        margin-top: 5px;
    }
    .loan__list__overdue {
        background-color: red;
        padding: 0 10px;
    }


    .loan__modal {
        padding: 5px;
        .loan__modal__search {
            justify-content: center;
            align-self: center;

            i {
                font-size: 25px;
                color: gray;
            }
        }
        .loan__modal__input {
            width: 80%;
            padding: 10px;
            border-radius: 10px;
            outline: none;
            border: 1px solid #ccc;
        }
        .loan__modal__back {
            color: gray;
            font-size: 26px;
            align-self: flex-start;
        }
        .loan__modal__date {
            background: #ccc;
            padding: 15px;
            border-radius: 10px;
            color: #162bd5;
        }
        .loan__modal__btn {
            background: #162bd5;
            color: white;
            padding: 15px;
            border-radius: 20px;
            i {
                margin-right: 5px;
            }
            p {
                font-size: 18px;
            }
        }
        .loan__modal__header {
            border-bottom: 1px solid gray;
            margin-bottom: 20px;

            i {
                font-size: 26px;
            }
        }
        .loan__modal__close {
            color: gray;
            font-size: 26px;
        }
      
    }
    .loan__types {
        color: white;
        margin-top: 20px;
        margin-bottom: 40px;

        .loan__type {
            padding: 14px;
            border-radius: 5px;
            background: #ccc;
            margin-right: 10px;
            color: black;
            cursor: pointer;
        }
        .loan__type__active {
            border-radius: 5px;
            background: #276fd0;
            color: white;
        }
    }

    .loan__modal__list::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
    }
    .loan__modal__list::-webkit-scrollbar-track {
        background-color: transparent;
    }
    .loan__modal__list::-webkit-scrollbar-thumb {
        background-color:  transparent;
    }
    .loan__modal__types {
        .loan__modal__type {
            margin-right: 5px;
            margin-left: 5px;
            background: #ccc;
            padding: 10px 20px;
            color: black;
            border-radius: 20px;
            font-size: 12px;
            font-weight: bold;
        }
        .loan__modal__type__active {
            background: #276fd0;
            color: white;
        }
    }
    .loan__modal__list {
        width: 100%;
        margin-top: 15px;
        overflow-y: auto;
        .loan__list__item {
            color: white;
            margin-bottom: 20px;
            width: 90%;
            border-radius: 10px;
            padding: 14px 30px;
            background: rgb(22,43,214);
            background: linear-gradient(90deg, rgba(22,43,214,1) 10%, rgba(33,144,200,0.9458158263305322) 79%, rgba(0,212,255,1) 100%);
            position: relative;

            .loan__list__edit {
                position: absolute;
                right: 20px;
                top: 20px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 1px solid white;
                i {
                    font-size: 15px;
                }
            }
            .loan__list__phone {
                position: absolute;
                right: 20px;
                top: 60px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 1px solid white;
                i {
                    font-size: 15px;
                    color: white;
                }
            }
            .loan__list__user {
                align-self: flex-start;
                color: white;
                margin-bottom: 20px !important;  
                font-size: 9px;
            }



            .loan__list__type {
                color: white;
            }
            .loan__list__amount {
                color: white;
                font-weight: bold;
                font-size: 30px;
                margin-bottom: 20px !important;
            }

            .loan__list__date {
                p {
                    font-size: 10px;
                    color: white;
                    font-weight: bold;
                } 
            }
          
        }

    }

    .loan__list__phone-number {
      font-size: 9px;
      align-self: flex-start;
      color: white;
      position: absolute;
      top: 25px;
      left: 30px;
    }

 
    .loan {
        .loan__header {
            margin-bottom: 30px;
            .item__logo {
                img {
                    width: 60px;
                }
            }
            .loan__logout {
                width: 30px;
                cursor: pointer;
            }
            .loan__logout:hover {
                opacity: 0.8;
            }
        }
        .loan__result__label {
            font-weight: bold;
        }
        .loan__overdue {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background: #2363d1;
            color: white;
        }
        .loan__show__overdue {
            color: #2363d1;
            cursor: pointer;
            font-weight: bold;
        }
        .client__list {
            
            .client__list__item {
                margin-top: 10px;
                padding: 20px;
                background: #ccc;
                border-radius: 10px;
                min-width: 300px;
                margin-right: 20px;
            }
        }
        .loan__all {
            color: #276fd0;
            cursor: pointer;
        }
        .loan__add__btn {
            cursor: pointer;
            background-color: #ccc;
            padding: 10px;
            border-radius: 20px;
            i {
                font-size: 22px;
                color:  #162cd5;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items:center;
            }
            p {
                font-size: 16px;
                color: #162cd5;
                font-weight: bold;
            }
        }

        .loan__list::-webkit-scrollbar {
            width: 5px;
            background-color: transparent;
        }
        .loan__list::-webkit-scrollbar-track {
            background-color: transparent;
        }
        .loan__list::-webkit-scrollbar-thumb {
            background-color:  transparent;
        }

        .loan__list__item {
            margin-right: 20px;
            min-width: 280px;
            border-radius: 10px;
            padding: 14px 30px;
            background: rgb(22,43,214);
            background: linear-gradient(90deg, rgba(22,43,214,1) 10%, rgba(33,144,200,0.9458158263305322) 79%, rgba(0,212,255,1) 100%);
            position: relative;
            .loan__list__edit {
                position: absolute;
                right: 20px;
                top: 20px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 1px solid white;
                i {
                    font-size: 15px;
                    color: white;
                }
            }
            .loan__list__phone {
                position: absolute;
                right: 20px;
                top: 60px;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 1px solid white;
                i {
                    font-size: 15px;
                    color: white;
                }
            }
        }

        .loan__list {
            width: 100%;
            overflow-x: auto;
            
            .loan__list__user {
                align-self: flex-start;
                color: white;
                margin-bottom: 20px !important;  
                font-size: 9px;
            }

            .loan__list__type {
                color: white;
            }
            .loan__list__amount {
                color: white;
                font-weight: bold;
                font-size: 30px;
                margin-bottom: 20px !important;
            }

            .loan__list__date {
                p {
                    font-size: 10px;
                    color: white;
                    font-weight: bold;
                } 
            }
        }
        
    }

    .loan__download  {
        i {
            color: white;
            font-size: 24px;
        }
        text-align: center;
        width: 230px;
        padding: 20px;
        color :white;
        background: linear-gradient(90deg, rgba(22,43,214,1) 10%, rgba(33,144,200,0.9458158263305322) 79%, rgba(0,212,255,1) 100%);
    }
</style>
